<template>
  <b-card>
    <header-details
      @on:cancel-edit="$router.push({name: 'duplicate'})"
    />
    <b-row>
      <b-col v-if="getSelectedCalendar">
        <div class="d-flex">
          <span class="modal-title font-weight-bold mt-1 mr-2">{{ $t('admin.duplicate.park-model') }}:</span>
          <d-text-field :value="getSelectedCalendar.club.name" class-name="bg-white" readonly size="sm"/>

          <span class="modal-title font-weight-bold mt-1 mr-2 ml-5">{{ $t('admin.duplicate.impacted-park') }}:</span>
          <d-text-field :value="impactedParks" class-name="bg-white" readonly style="width: 60%" size="sm"/>
        </div>
        <div class="d-flex mt-2">
          <span class="modal-title font-weight-bold mt-1 mr-2">{{ $t('admin.duplicate.used-cal') }}:</span>
          <d-text-field
            :value="getSelectedCalendar.name"
            readonly
            size="sm"
            class-name="bg-white"
            class="cursor-disabled"
          />
        </div>
      </b-col>
      <b-col v-else>
        <div class="d-flex">
          <span class="modal-title font-weight-bold mr-3 mt-1">{{ $t('admin.duplicate.impacted-park') }}:</span>
          <d-text-field :value="impactedParks" class-name="bg-white" readonly style="width: 60%" size="sm"/>
        </div>
      </b-col>
    </b-row>

    <b-row class="d-flex justify-content-center mt-2">
      <b-col v-if="getSelectedCalendar" cols="7">
        <fieldset>
          <legend class="d-flex justify-content-center font-weight-bold h6">
            {{ getSelectedCalendar.club.name }}
          </legend>
          <d-table
            v-if="getSelectedCalendar.blocks"
            :identifier="'block-index-0-0'"
            :tablefields="fields"
            :items="getSelectedCalendar.blocks"
            :per-page="500"
            :totalRows="getSelectedCalendar.blocks.length"
            :is-busy="false"
            :per-page-enabled="false"
            :tr-cursor-pointer="false"
            :overflow-enabled="true"
            :show-pagination="false"
          />
          <div class="d-flex justify-content-center">
            <d-button
              :text="$t('admin.duplicate.set-data')"
              class="d-btn-sm d-btn btn d-btn-success font-text-title mt-3"
              @on:button-click="onDuplicateAll"
            />
          </div>
        </fieldset>
      </b-col>
      <b-col cols="12">
        <div style="height: 40px" class="w-100 bg-light-red d-flex justify-content-center align-items-center">
          {{ $t('admin.duplicate.block-to-edit') }}
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col
        v-for="(item, index) of getBlockList"
        :key="'checked-' + index"
        cols="6"
        class="mt-3"
      >
        <fieldset>
          <legend style="width: auto" class="d-flex font-weight-bold h6">
            <span class="ml-2 mr-2">{{ item.club.name }} - {{ item.calendar.name }}</span>
          </legend>
          <d-table
            :identifier="'block-' + index"
            :tablefields="fields"
            :items="item.categories"
            :per-page="500"
            :totalRows="item.categories.length"
            :is-busy="false"
            :per-page-enabled="false"
            :tr-cursor-pointer="false"
            :overflow-enabled="true"
            :show-pagination="false"
          />
        </fieldset>
      </b-col>
    </b-row>
    <b-row class="mt-2">

      <b-col cols="12" align="middle">
        <d-button
          :text="$t('admin.duplicate.validate-data')"
          class="d-btn-sm d-btn btn d-btn-success font-text-title mt-3"
          @on:button-click="displayModal"
        />
      </b-col>
      <progress-bar-modal
        modalId="identifier-validate"
        :display="display"
        title="general.actions.notification"
        :is-busy="isBusy"
        :count="count"
        :text="$t('admin.duplicate.ask-for-confirmation')"
        :hide="hide"
        @on:cancel="hide = !hide"
        @on:confirm="onValidate"
      />
    </b-row>
  </b-card>
</template>
<script>
import HeaderDetails from "@views/duplicate/HeaderDetails";
import ProgressBarModal from "@custom/duplicate/ProgressBarModal";
import {_get, _put} from "@api/services/httpService";
import {isNotUndefinedAndNotNull} from "@/utils/classes";
import {cloneData} from "@/utils/form";

export default {
  data: () => ({
    hide: false,
    display: false,
    duplicateAllEnabled: false,
    count: {
      success: 0,
      failed: 0,
      totalCount: 0,
    },
    isBusy: false
  }),
  components: {ProgressBarModal, HeaderDetails},
  computed: {
    getSelectedCalendar() {
      return this.$store.getters['calendar/getSelectedCalendarModel'];
    },
    getBlockList() {
      return this.$store.getters['block/getCheckedBlocks'];
    },
    fields() {
      return [
        {
          key: 'name',
          label: this.$t('admin.duplicate.calendar.blocks.label'),
          class: 'text-center'
        },
        {
          key: 'startAt',
          label: this.$t('admin.duplicate.calendar.blocks.startAt'),
          class: 'text-center'
        },
        {
          key: 'endAt',
          label: this.$t('admin.duplicate.calendar.blocks.endAt'),
          class: 'text-center'
        },
        {
          key: 'instalmentPercentage',
          label: this.$t('admin.duplicate.calendar.blocks.instalment'),
          class: 'text-center'
        },
        {
          key: 'user_client_step_booking_duration',
          label: this.$t('admin.duplicate.calendar.blocks.stepClient'),
          class: 'text-center'
        },
        {
          key: 'user_club_step_booking_duration',
          label: this.$t('admin.duplicate.calendar.blocks.stepClub'),
          class: 'text-center'
        },
        {
          key: 'actions',
          label: this.$t('general.actions.actions')
        }
      ];
    },
    impactedParks() {
      let label = '';
      for (let i = 0; i < this.getBlockList.length; i++) {
        label += i < this.getBlockList.length - 1 ? this.getBlockList[i].club.name + ',' : this.getBlockList[i].club.name;
      }
      return label;
    },
  },
  methods: {
    getItems(list) {
      return list;
    },
    displayModal() {
      this.count.success = 0;
      this.count.failed = 0;
      this.count.totalCount = this.getBlockList.length;
      this.display = !this.display;
    },
    onDuplicateAll() {
      this.duplicateAllEnabled = true;
      this.$store.commit('block/updateBlockList', this.getSelectedCalendar.blocks);
    },
    async onValidate() {
      if (this.isBusy === false) {
        this.isBusy = true;
        this.count = {success: 0, failed: 0, totalCount: this.getBlockList.length}

        for (let g = 0; g < this.getBlockList.length; g++) {
          const group = this.getBlockList[g];
          if (false === this.duplicateAllEnabled) {
            for (let i = 0; i < group.categories.length; i++) {
              const block = group.categories[i];

              await _put(block['@id'], {
                name: block.name,
                startAt: block.startAt === '' ? null : block.startAt,
                endAt: block.endAt === '' ? null : block.endAt,
                instalmentPercentage: block.instalmentPercentage,
                userClientStepBookingDuration: block.userClientStepBookingDuration,
                userClubStepBookingDuration: block.userClubStepBookingDuration,
              })
                .then((response) => {
                  this.count.success++;
                })
                .catch((err) => {
                  this.count.failed++;
                })
                .finally(() => {
                  if (this.count.success + this.count.failed === this.count.totalCount) {
                    this.isBusy = false;
                    setTimeout(() => {
                      this.$router.push({name: 'duplicate'})
                    }, 800);
                  }
                })
              ;
            }
          } else {
            const dataBlocks = [];

            for (const block of group.categories) {

              dataBlocks.push({
                name: block.name,
                startAt: block.startAt,
                endAt: block.endAt,
                instalmentPercentage: block.instalmentPercentage,
                maxFormulaBookingsCountLimit: block.maxFormulaBookingsCountLimit,
                maxParticipantsCountLimit: block.maxParticipantsCountLimit,
                minBookingInterval: block.minBookingInterval,
                paymentMethods: block.paymentMethods,
                registrationAvailableOnline: block.registrationAvailableOnline,
                userClientStepBookingDuration: block.userClientStepBookingDuration,
                userClubStepBookingDuration: block.userClubStepBookingDuration,
              });
            }
            await _put(group.calendar['@id'], {blocks: dataBlocks})
              .then((response) => {
                this.count.success++;
              })
              .catch((err) => {
                this.count.failed++;
              })
              .finally(() => {
                if (this.count.success + this.count.failed === this.count.totalCount) {
                  this.isBusy = false;
                  setTimeout(() => {
                    this.$router.push({name: 'duplicate'})
                  }, 800);
                }
              })
            ;
          }
        }
      }
    }
  },
  mounted() {
    console.log('on mounted')
    if (this.getSelectedCalendar) {
      _get('/clubs/playgrounds/timetables/blocks?timetable.id[]=' + this.getSelectedCalendar.id)
        .then((response) => {
          const calendarModel = cloneData(this.getSelectedCalendar);

          calendarModel.blocks = response.data['hydra:member'];
          calendarModel.blocks.forEach((el) => {
            el.actions = ['delete'];
            el.update = true;
          });
          console.log(calendarModel.blocks, 'loog me on  before sort ');
          calendarModel.blocks.sort((b, a) => (a.startAt > b.startAt) ? 1 : ((b.startAt > a.startAt) ? -1 : 0))
          console.log(calendarModel.blocks, 'loog me on sort');
          this.$store.commit('calendar/setSelectedCalendarModel', calendarModel);
        })
      ;
    }
  }
}
</script>
<style scoped lang="scss">

.bg-light-red {
  background-color: #FF0101;
  border-radius: 5px;
  color: white;
  font-weight: bold;
}

/deep/ .modal-content {
  border-radius: 8px;
  width: calc(100% - 294px);
  left: 100px;
}

/deep/ .vs-checkbox--input:checked + .vs-checkbox .vs-icon {
  font-size: initial;
  font-family: 'Material Icons';
}

@import "@lazy/_b-card.scss";
</style>
